import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hora'
})
export class HoraPipe implements PipeTransform {

  transform(value: string): string {
    const date = new Date(value);
    const hora = date.getHours();
    const minuto = date.getMinutes();
    const segundo = date.getSeconds();

    return `${hora}:${minuto}:${segundo}`;

  }

}
