import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map  } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { Register } from '../models/register.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }
  
  check(): boolean {
    return localStorage.getItem('token') ? true : false
  }

  checkAnamneseUser(){
    return this.http.get<any>(`${environment.api_local}/usuarios/check-anamnese`)
  }
  
  login(user: User): Observable<User>{
    return this.http.post<any>(`${environment.api_local}/auth/login`,user).pipe(map(users => {
      localStorage.setItem('token',users.access_token);
      localStorage.setItem('user_id',users.id);
      return users;
    }));
  }
  
  register(register: Register): Observable<Register>{
    return this.http.post<Register>(`${environment.api_local}/auth/register`,register);
  }
  profile(): Observable<any>{
    return this.http.get<any>(`${environment.api_local}/auth/profile`)
  }
}
