import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  private handleAuthError(err: HttpErrorResponse) {
    if (err.status === 401 && this.router.url != '/login') {
      localStorage.clear();
      this.router.navigateByUrl('/login');
    }
    if (err.status === 422) {
      let message = '';
      const errors = err.error.errors;
      Object.keys(errors).forEach(k => message += errors[k] + '\n');
      return throwError(message);
    }
    return throwError(err);
  }
  
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    const token = localStorage.getItem('token');
    const requestUrl: Array<any> = request.url.split('/');
    const apiUrl: Array<any> = environment.api_local.split('/');
    if(token && (requestUrl[2] === apiUrl[2]) ){
      const newRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(newRequest);
    }else{
      return next.handle(request)
      .pipe(catchError((err) => this.handleAuthError(err)))
    }

  }
}
