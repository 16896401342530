/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : ionic6Template Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'introduction-page',
    loadChildren: () => import('./pages/introduction-page/introduction-page.module').then(m => m.IntroductionPagePageModule)
  },
  {
    path: 'welcome-page',
    loadChildren: () => import('./pages/welcome-page/welcome-page.module').then(m => m.WelcomePagePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate:[LoginGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'activity-tracker',
    loadChildren: () => import('./pages/activity-tracker/activity-tracker.module').then(m => m.ActivityTrackerPageModule)
  },
  {
    path: 'add-alarm',
    loadChildren: () => import('./pages/add-alarm/add-alarm.module').then(m => m.AddAlarmPageModule)
  },
  {
    path: 'workout-schedule',
    loadChildren: () => import('./pages/workout-schedule/workout-schedule.module').then(m => m.WorkoutSchedulePageModule)
  },
  {
    path: 'sleep-schedule',
    loadChildren: () => import('./pages/sleep-schedule/sleep-schedule.module').then(m => m.SleepSchedulePageModule)
  },
  {
    path: 'your-goal',
    loadChildren: () => import('./pages/your-goal/your-goal.module').then(m => m.YourGoalPageModule)
  },
  {
    path: 'sleep-tracker',
    loadChildren: () => import('./pages/sleep-tracker/sleep-tracker.module').then(m => m.SleepTrackerPageModule)
  },
  {
    path: 'progress-photos',
    loadChildren: () => import('./pages/progress-photos/progress-photos.module').then(m => m.ProgressPhotosPageModule)
  },
  {
    path: 'workout-tracker',
    loadChildren: () => import('./pages/workout-tracker/workout-tracker.module').then(m => m.WorkoutTrackerPageModule)
  },
  {
    path: 'meal-schedule',
    loadChildren: () => import('./pages/meal-schedule/meal-schedule.module').then(m => m.MealSchedulePageModule)
  },
  {
    path: 'workout-details',
    loadChildren: () => import('./pages/workout-details/workout-details.module').then(m => m.WorkoutDetailsPageModule)
  },
  {
    path: 'fullbody-workout',
    loadChildren: () => import('./pages/fullbody-workout/fullbody-workout.module').then(m => m.FullbodyWorkoutPageModule)
  },
  {
    path: 'complete-profile',
    loadChildren: () => import('./pages/complete-profile/complete-profile.module').then(m => m.CompleteProfilePageModule)
  },
  {
    path: 'comparison',
    loadChildren: () => import('./pages/comparison/comparison.module').then(m => m.ComparisonPageModule)
  },
  {
    path: 'breakfast',
    loadChildren: () => import('./pages/breakfast/breakfast.module').then(m => m.BreakfastPageModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./pages/success/success.module').then(m => m.SuccessPageModule)
  },
  {
    path: 'add-schedule',
    loadChildren: () => import('./pages/add-schedule/add-schedule.module').then(m => m.AddSchedulePageModule)
  },
  {
    path: 'meal-planner',
    loadChildren: () => import('./pages/meal-planner/meal-planner.module').then(m => m.MealPlannerPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'recipes',
    loadChildren: () => import('./pages/recipes/recipes.module').then(m => m.RecipesPageModule)
  },
  {
    path: 'result',
    loadChildren: () => import('./pages/result/result.module').then(m => m.ResultPageModule)
  },
  {
    path: 'other-navigationpage',
    loadChildren: () => import('./pages/other-navigationpage/other-navigationpage.module').then(m => m.OtherNavigationpagePageModule)
  },
  {
    path: 'anamnese',
    loadChildren: () => import('./pages/anamnese/anamnese.module').then( m => m.AnamnesePageModule)
  },
  {
    path: 'altura',
    loadChildren: () => import('./pages/altura/altura.module').then( m => m.AlturaPageModule)
  },
  {
    path: 'idade',
    loadChildren: () => import('./pages/idade/idade.module').then( m => m.IdadePageModule)
  },
  {
    path: 'objetivo',
    loadChildren: () => import('./pages/objetivo/objetivo.module').then( m => m.ObjetivoPageModule)
  },
  {
    path: 'exercicio',
    loadChildren: () => import('./pages/exercicio/exercicio.module').then( m => m.ExercicioPageModule)
  },
  {
    path: 'saude',
    loadChildren: () => import('./pages/saude/saude.module').then( m => m.SaudePageModule)
  },
  {
    path: 'resultado',
    loadChildren: () => import('./pages/resultado/resultado.module').then( m => m.ResultadoPageModule)
  },
  {
    path: 'dieta',
    loadChildren: () => import('./pages/dieta/dieta.module').then( m => m.DietaPageModule)
  },
  {
    path: 'diet-dinner',
    loadChildren: () => import('./pages/diet-dinner/diet-dinner.module').then( m => m.DietDinnerPageModule)
  },
  {
    path: 'diet-details',
    loadChildren: () => import('./pages/diet-details/diet-details.module').then( m => m.DietDetailsPageModule)
  },
  {
    path: 'atualiza-peso',
    loadChildren: () => import('./pages/atualiza-peso/atualiza-peso.module').then( m => m.AtualizaPesoPageModule)
  },
  {
    path: 'menu-diet',
    loadChildren: () => import('./pages/menu-diet/menu-diet.module').then( m => m.MenuDietPageModule)
  },
  {
    path: 'week-diet',
    loadChildren: () => import('./pages/week-diet/week-diet.module').then( m => m.WeekDietPageModule)
  },
  {
    path: 'snack-week',
    loadChildren: () => import('./pages/snack-week/snack-week.module').then( m => m.SnackWeekPageModule)
  },
  {
    path: 'diet-pre-training',
    loadChildren: () => import('./pages/diet-pre-training/diet-pre-training.module').then( m => m.DietPreTrainingPageModule)
  },
  {
    path: 'diet-breakfast',
    loadChildren: () => import('./pages/diet-breakfast/diet-breakfast.module').then( m => m.DietBreakfastPageModule)
  },
  {
    path: 'diet-afternoon-snack',
    loadChildren: () => import('./pages/diet-afternoon-snack/diet-afternoon-snack.module').then( m => m.DietAfternoonSnackPageModule)
  },
  {
    path: 'exams',
    loadChildren: () => import('./pages/exams/exams.module').then( m => m.ExamsPageModule)
  },
  {
    path: 'exams2',
    loadChildren: () => import('./pages/exams2/exams2.module').then( m => m.Exams2PageModule)
  },
  {
    path: 'exams3',
    loadChildren: () => import('./pages/exams3/exams3.module').then( m => m.Exams3PageModule)
  },
  {
    path: 'exams4',
    loadChildren: () => import('./pages/exams4/exams4.module').then( m => m.Exams4PageModule)
  },
  {
    path: 'exams5',
    loadChildren: () => import('./pages/exams5/exams5.module').then( m => m.Exams5PageModule)
  },
  {
    path: 'exams6',
    loadChildren: () => import('./pages/exams6/exams6.module').then( m => m.Exams6PageModule)
  },
  {
    path: 'menu-exames',
    loadChildren: () => import('./pages/menu-exames/menu-exames.module').then( m => m.MenuExamesPageModule)
  },

  {
    path: 'exams7',
    loadChildren: () => import('./pages/exams7/exams7.module').then( m => m.Exams7PageModule)
  },
  {
    path: 'menu-exercicios',
    loadChildren: () => import('./pages/menu-exercicios/menu-exercicios.module').then( m => m.MenuExerciciosPageModule)
  },
  {
    path: 'week-exercicios',
    loadChildren: () => import('./pages/week-exercicios/week-exercicios.module').then( m => m.WeekExerciciosPageModule)
  },
  {
    path: 'day-exercicio',
    loadChildren: () => import('./pages/day-exercicio/day-exercicio.module').then( m => m.DayExercicioPageModule)
  },
  {
    path: 'details-exercicios',
    loadChildren: () => import('./pages/details-exercicios/details-exercicios.module').then( m => m.DetailsExerciciosPageModule)
  },
  {
    path: 'tempo-treino',
    loadChildren: () => import('./pages/tempo-treino/tempo-treino.module').then( m => m.TempoTreinoPageModule)
  },
  {
    path: 'hidratar-form',
    loadChildren: () => import('./pages/hidratar-form/hidratar-form.module').then( m => m.HidratarFormPageModule)
  },
  {
    path: 'day-exercicio-segunda',
    loadChildren: () => import('./pages/day-exercicio-segunda/day-exercicio-segunda.module').then( m => m.DayExercicioSegundaPageModule)
  },
  {
    path: 'day-exercicio-sexta',
    loadChildren: () => import('./pages/day-exercicio-sexta/day-exercicio-sexta.module').then( m => m.DayExercicioSextaPageModule)
  },
  {
    path: 'day-details-one',
    loadChildren: () => import('./pages/day-details-one/day-details-one.module').then( m => m.DayDetailsOnePageModule)
  },
  {
    path: 'day-details-dois',
    loadChildren: () => import('./pages/day-details-dois/day-details-dois.module').then( m => m.DayDetailsDoisPageModule)
  },
  {
    path: 'day-details-tres',
    loadChildren: () => import('./pages/day-details-tres/day-details-tres.module').then( m => m.DayDetailsTresPageModule)
  },
  {
    path: 'day-details-quatro',
    loadChildren: () => import('./pages/day-details-quatro/day-details-quatro.module').then( m => m.DayDetailsQuatroPageModule)
  },
  {
    path: 'day-details-cinco',
    loadChildren: () => import('./pages/day-details-cinco/day-details-cinco.module').then( m => m.DayDetailsCincoPageModule)
  },
  {
    path: 'day-details-seis',
    loadChildren: () => import('./pages/day-details-seis/day-details-seis.module').then( m => m.DayDetailsSeisPageModule)
  },
  {
    path: 'day-details-sete',
    loadChildren: () => import('./pages/day-details-sete/day-details-sete.module').then( m => m.DayDetailsSetePageModule)
  },
  {
    path: 'day-details-oito',
    loadChildren: () => import('./pages/day-details-oito/day-details-oito.module').then( m => m.DayDetailsOitoPageModule)
  },
  {
    path: 'day-details-nove',
    loadChildren: () => import('./pages/day-details-nove/day-details-nove.module').then( m => m.DayDetailsNovePageModule)
  },
  {
    path: 'day-details-dex',
    loadChildren: () => import('./pages/day-details-dex/day-details-dex.module').then( m => m.DayDetailsDexPageModule)
  },
  {
    path: 'day-details-onze',
    loadChildren: () => import('./pages/day-details-onze/day-details-onze.module').then( m => m.DayDetailsOnzePageModule)
  },
  {
    path: 'day-details-doze',
    loadChildren: () => import('./pages/day-details-doze/day-details-doze.module').then( m => m.DayDetailsDozePageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
